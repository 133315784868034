.fade-in-on-scroll {
  text-align: center;
  color: rgb(5, 196, 5);
  opacity: 0;
  transform: 
    translateY(60px); /* A szöveg induláskor lentebb van */
    

  transition: 
   opacity 2s ease-out,
   transform 2s ease-out;
}

.fade-in-on-scroll.visible {
  opacity: 1;
  transform: translateY(0); /* A szöveg a helyére csúszik */
}

